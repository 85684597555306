<template>
  <div class=" background">
    <div class="container container--column">
      <label for="name" class="outline-text">{{$t('chooseNickname')}}</label><input
      class="home-input"
      :placeholder="$t('nickname')"
      type="text" name="name"
      v-model="name" id="name">
    <button @click="() => createGame()" class="button" :disabled="name.length === 0">
      {{$t('createGame')}}
    </button>
    <button @click="() => createGame(true)" class="button" :disabled="name.length === 0">
      {{`${$t('createGame')} (3D)`}}
    </button>
    <br>
      <label for="game" class="outline-text">{{$t('chooseGameId')}}</label><input
      class="home-input"
      :placeholder="$t('joinGameText')"
      type="text" name="name"
      v-model="gameId" id="game">
    <button
      @click="() => joinGame()"
      class="button"
      :disabled="gameId.length === 0 || name.length === 0">
       {{`${$t('joinGame')}`}}
    </button>
    <button
      @click="() => joinGame(true)"
      class="button"
      :disabled="gameId.length === 0 || name.length === 0">
      {{`${$t('joinGame')} (3D)`}}
    </button>
  </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

type ComponentData = {
  name: string;
  gameId: string;
};
export default defineComponent({
  name: 'Home',
  data(): ComponentData {
    return {
      name: '',
      gameId: '',
    };
  },
  mounted() {
    this.name = this.$store.state.name;
  },
  methods: {
    async createGame(advanced?: boolean) {
      const request = await fetch(
        `${process.env.VUE_APP_API_LOCATION}/game/create`,
      );
      const { gameId } = await request.json();
      this.$store.commit('setName', this.name);
      this.$router.push(`/game${advanced ? '3d' : ''}/${gameId}`);
    },
    joinGame(advanced?: boolean) {
      this.$router.push(`/game${advanced ? '3d' : ''}/${this.gameId}`);
      this.$store.commit('setName', this.name);
    },
  },
});
</script>
