
import { defineComponent } from 'vue';

type ComponentData = {
  name: string;
  gameId: string;
};
export default defineComponent({
  name: 'Home',
  data(): ComponentData {
    return {
      name: '',
      gameId: '',
    };
  },
  mounted() {
    this.name = this.$store.state.name;
  },
  methods: {
    async createGame(advanced?: boolean) {
      const request = await fetch(
        `${process.env.VUE_APP_API_LOCATION}/game/create`,
      );
      const { gameId } = await request.json();
      this.$store.commit('setName', this.name);
      this.$router.push(`/game${advanced ? '3d' : ''}/${gameId}`);
    },
    joinGame(advanced?: boolean) {
      this.$router.push(`/game${advanced ? '3d' : ''}/${this.gameId}`);
      this.$store.commit('setName', this.name);
    },
  },
});
